import { enableProdMode } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import { environment } from '@env/environment';

import { AppComponent } from './app/app.component';
import { appConfig } from './app/app.config';

if ( environment.production ) {
  enableProdMode();
}

bootstrapApplication( AppComponent, appConfig )
  .catch( ( err ) => console.error( err ) );
