import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, NgZone, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { NavigationEnd, Router } from '@angular/router';
import { Notification, NOTIFICATION_TYPE } from '@core/models/app.model';
import { NotifyService } from '@core/services/notifyService.service';


@Component( {
  selector:        'ume-notify',
  styleUrls:       [ './notify.component.scss' ],
  templateUrl:     './notify.component.html',
  standalone:      true,
  imports:         [
    CommonModule,
    MatButtonModule,
    MatIconModule,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
} )
export class NotifyComponent implements OnInit {
  notificationType = NOTIFICATION_TYPE;
  notification: Notification | null = null;

  constructor(
    private notifyService: NotifyService,
    private router: Router,
    private _zone: NgZone,
    private _cdr: ChangeDetectorRef,
  ) {
    this.router.events.subscribe( val => {
      if ( val instanceof NavigationEnd ) {
        this.hide();
        this._cdr.markForCheck();
      }
    } );
  }

  ngOnInit(): void {
    this.notifyService.notificationEmitter
      .subscribe( ( notification: Notification ) => {
          this.notification = notification;
          const timeout = notification.duration ?? 4000;
          this._cdr.detectChanges();

          if ( this.notification.type === NOTIFICATION_TYPE.SUCCESS ) {
            this._zone.runOutsideAngular( () => {
              setTimeout( () => {
                this.hide();
                this._cdr.detectChanges();
              }, timeout );
            } );
          }

          if ( this.notification.type === NOTIFICATION_TYPE.INFO ) {
            this._zone.runOutsideAngular( () => {
              setTimeout( () => {
                this.hide();
                this._cdr.detectChanges();
              }, timeout );
            } );
          }
        },
      );
  }

  public get notificationTitle(): string {
    if ( !this.notification ) {
      return '';
    }
    switch ( this.notification.type ) {
      case NOTIFICATION_TYPE.SUCCESS:
        return 'Success';
      case NOTIFICATION_TYPE.INFO:
        return 'Info';
      case NOTIFICATION_TYPE.WARN:
      case NOTIFICATION_TYPE.WARN_ONLY:
        return 'Warning';
      case NOTIFICATION_TYPE.ERROR:
      case NOTIFICATION_TYPE.ERROR_ONLY:
        return 'Error';
      default:
        return 'Success';
    }
  }

  public hide(): void {
    this.notification = null;
  }
}
