import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { getSessionUser } from '@auth/repository/auth.repository';
import { Guid } from '@core/models/guid';
import { environment } from '@env/environment';
import { cookies } from '@shared/domain/ume-urls';
import { getCookieByName } from '@shared/helpers/helpers';
import { Observable } from 'rxjs';
import COOKIE_NAME = cookies.COOKIE_NAME;


@Injectable()
export class AppInterceptor implements HttpInterceptor {
  private userCompanyId: Guid | undefined;

  constructor() { }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    let clone;
    this.userCompanyId = getSessionUser().selectedCompany?.guid;

    if ( request.url.startsWith( '/' ) ) {
      const setHeaders: any = {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'X-ume-CompanyId': request.headers.get( 'X-ume-CompanyId' ) || this.userCompanyId || '',
      };
      const xsrfCookie = getCookieByName( cookies.COOKIE_NAME() );

      if ( xsrfCookie ) {
        setHeaders[cookies.HEADER_NAME()] = xsrfCookie;
      } else {
        console.log( 'Cookie not found: ', COOKIE_NAME(), document.cookie );
      }
      clone = request.clone( {
        url:             environment.API + request.url,
        withCredentials: true,
        setHeaders,
      } );
    } else {
      clone = request;
    }

    return next.handle( clone );
  }
}
