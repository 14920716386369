import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '@auth/repository';
import { hasServiceSync, isLoggedInUser } from '@auth/repository/auth.repository';
import { UME_SERVICE } from '@core/service-subscriptions/ume-services';
import { ACCESS_TOKEN, HOME_PAGE, LOGIN_PAGE, PROVIDER } from '@shared/constants/ume-constants';
import { map, tap } from 'rxjs/operators';


export const findUrlGuardFn: CanActivateFn = ( route: ActivatedRouteSnapshot, state: RouterStateSnapshot ): boolean => {
  const authService = inject( AuthService );
  const _router = inject( Router );

  if ( !isLoggedInUser() ) {
    const url: string | null = state?.url || null;
    let token = url?.slice( url?.indexOf( ACCESS_TOKEN ) );
    const isToken = token && token.length > 50;
    token = isToken ? token?.slice( ACCESS_TOKEN.length + 1 ) : token;

    if ( url && isToken ) {
      authService.logOutAsync()
        .pipe(
          tap( () => {
            _router.navigate( [ '/', LOGIN_PAGE, 't', token ] ).then();
          } ),
          map( () => false ),
        );
    } else {
      _router.navigate( [ '/', LOGIN_PAGE ] ).then();
    }
    return false;
  }

  hasServiceSync( UME_SERVICE.PROVIDER )
    ? _router.navigate( [ '/', PROVIDER, HOME_PAGE ] ).then()
    : _router.navigate( [ '/', HOME_PAGE ] ).then();
  return true;
};