import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthModule } from '@auth/auth.module';
import { NullComponent } from '@core/components/null/null.component';
import { PageNotFoundComponent } from '@core/components/page-not-found/page-not-found.component';
import { RestrictedAccessComponent } from '@core/components/restricted-access/restricted-access.component';
import { adminGuardFn } from '@core/guards/admin.guard';
import { authGuardFn } from '@core/guards/auth.guard';
import { dashboardGuard } from '@core/guards/dashboard.guard';
import { findUrlGuardFn } from '@core/guards/find-url.guard';
import { navigationWizardGuardFn } from '@core/guards/navigation-wizard.guard';
import { HOME_PAGE, NULL_PAGE } from '@shared/constants/ume-constants';
import { UserModule } from '@user/user.module';

import { BaseLayoutComponent } from './layout';
import { AboutModule } from './routes/about/about.module';
import { VPAccountsModule } from './routes/accounts/vp-accounts.module';
import { AdminModule } from './routes/admin/admin.module';
import { CompanyModule } from './routes/company/company.module';
import { DashboardModule } from './routes/dashboard/dashboard.module';
import { MyProfileModule } from './routes/my-profile/my-profile.module';
import { ManagedServicesModule } from './routes/provider/modules/managed-services/managed-services.module';
import { ProviderModule } from './routes/provider/provider.module';
import { QuestionnaireModule } from './routes/questionnaire/questionnaire.module';
import { ScoringModule } from './routes/scoring/scoring.module';
import { SearchModule } from './routes/search/search.module';
import { UpgradePlanModule } from './routes/upgrade-plan/upgrade-plan.module';


export const appRoutes: Routes = [
  {
    path:        '',
    component:   NullComponent,
    pathMatch:   'full',
    canActivate: [ authGuardFn, findUrlGuardFn ],
  },
  {
    path:      NULL_PAGE, // Log out intermediate step
    component: NullComponent,
  },
  {
    path:         'auth',
    loadChildren: (): Promise<typeof AuthModule> =>
                    import('./routes/auth/auth.module').then( m => m.AuthModule ),
  },
  {
    path:         'login',
    loadChildren: (): Promise<typeof AuthModule> =>
                    import('./routes/auth/auth.module').then( m => m.AuthModule ),
  },
  {
    path:       'signup',
    redirectTo: 'auth/signup',
  },
  // BaseLayoutComponent container for app
  {
    path:        '',
    canActivate: [ authGuardFn ],
    component:   BaseLayoutComponent,
    children:    [
      {
        path:         HOME_PAGE,
        canActivate:  [ navigationWizardGuardFn, dashboardGuard ],
        loadChildren: (): Promise<typeof DashboardModule> =>
                        import('./routes/dashboard/dashboard.module').then( m => m.DashboardModule ),
      },
      {
        path:         'search',
        loadChildren: (): Promise<typeof SearchModule> =>
                        import('./routes/search/search.module').then( m => m.SearchModule ),
      },
      {
        path:         'my-profile',
        loadChildren: (): Promise<typeof MyProfileModule> =>
                        import('./routes/my-profile/my-profile.module').then( m => m.MyProfileModule ),
      },
      {
        path:         'questionnaire',
        loadChildren: (): Promise<typeof QuestionnaireModule> =>
                        import('./routes/questionnaire/questionnaire.module').then( m => m.QuestionnaireModule ),
      },
      {
        path:         'company',
        canActivate:  [ navigationWizardGuardFn ],
        loadChildren: (): Promise<typeof CompanyModule> =>
                        import('./routes/company/company.module').then( m => m.CompanyModule ),
      },
      {
        path:         'user',
        canActivate:  [ navigationWizardGuardFn ],
        loadChildren: (): Promise<typeof UserModule> =>
                        import('./routes/user/user.module').then( m => m.UserModule ),
      },
      {
        path:         'scoring',
        loadChildren: (): Promise<typeof ScoringModule> =>
                        import('./routes/scoring/scoring.module').then( m => m.ScoringModule ),
      },
      {
        path:         'admin',
        loadChildren: (): Promise<typeof AdminModule> =>
                        import('./routes/admin/admin.module').then( m => m.AdminModule ),
      },
      {
        path:         'plans',
        loadChildren: (): Promise<typeof UpgradePlanModule> =>
                        import('./routes/upgrade-plan/upgrade-plan.module').then( m => m.UpgradePlanModule ),
      },
      {
        path:         'accounts',
        loadChildren: (): Promise<typeof VPAccountsModule> =>
                        import('./routes/accounts/vp-accounts.module').then( m => m.VPAccountsModule ),
      },
      {
        path:         'about',
        loadChildren: (): Promise<typeof AboutModule> =>
                        import('./routes/about/about.module').then( m => m.AboutModule ),
      },
      {
        path:         'provider',
        loadChildren: (): Promise<typeof ProviderModule> =>
                        import('./routes/provider/provider.module').then( m => m.ProviderModule ),
        canActivate:  [ adminGuardFn ],
      },
      {
        path:         'managed-services',
        loadChildren: (): Promise<typeof ManagedServicesModule> =>
                        import('./routes/provider/modules/managed-services/managed-services.module')
                          .then( m => m.ManagedServicesModule ),
        canActivate:  [ adminGuardFn ],
      },
      {
        path:      'restricted-user-rights',
        component: RestrictedAccessComponent,
      },
      {
        path:       'distributor/policy-file/edit', // need to be changed in email
        redirectTo: 'my-profile/policy-files',
      },
      {
        path:       'policy-files/edit', // need to be changed in email
        redirectTo: 'my-profile/policy-files',
      },
    ],
  },
  {
    path:        '**',
    component:   PageNotFoundComponent,
    canActivate: [ authGuardFn ],
    pathMatch:   'full',
  },
];


@NgModule( {
  imports: [
    RouterModule.forRoot( appRoutes, { anchorScrolling: 'enabled' } ),
  ],
  exports: [ RouterModule ],
} )
export class AppRoutingModule {
}
