import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { hasServiceSync, isLoggedIn$, userSelectedCompany$ } from '@auth/repository/auth.repository';
import { UME_SERVICE } from '@core/service-subscriptions/ume-services';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { HOME_PAGE, LOGIN_PAGE, PROVIDER } from '@shared/constants/ume-constants';
import { delay, filter, switchMap, tap } from 'rxjs/operators';


@UntilDestroy()
@Component( {
  selector:    'ume-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls:   [ './page-not-found.component.scss' ],
} )
export class PageNotFoundComponent implements OnInit {
  constructor(
    private router: Router,
  ) {}

  ngOnInit(): void {
    isLoggedIn$
      .pipe(
        tap( ( isLogged: boolean ) => {
          if ( !isLogged ) {
            this.router.navigate( [ '/', LOGIN_PAGE ] ).then();
          }
        } ),
        filter( Boolean ),
        switchMap( _ => userSelectedCompany$ ),
        delay( 3000 ),
        untilDestroyed( this ),
      )
      .subscribe( () => {
        hasServiceSync( UME_SERVICE.PROVIDER )
          ? this.router.navigate( [ '/', PROVIDER, HOME_PAGE ] ).then()
          : this.router.navigate( [ '/', HOME_PAGE ] ).then();
      } );
  }
}
