import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { NavigationWizardService } from '@core/services/navigation-wizard.service';
import { updateDataFromLocalStorageByKey } from '@shared/helpers/helpers';


export const navigationWizardGuardFn: CanActivateFn = (): boolean => {
  const navigationWizard = inject( NavigationWizardService );
  const router = inject( Router );
  
  if ( navigationWizard.hasNext ) {
    const links = navigationWizard.next;
    router.navigateByUrl( links.url ).then();
    updateDataFromLocalStorageByKey( sessionStorage, 'sessionUser', '_links', navigationWizard.wizard );
    updateDataFromLocalStorageByKey( localStorage, 'sessionUser', '_links', navigationWizard.wizard );
    return false;
  }

  return true;
};
